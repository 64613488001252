@import-normalize;

@font-face {
  font-family: 'Circe';
  src: url('../fonts/Circe/Circe-Regular.woff')
      format('woff'),
    url('../fonts/Circe/Circe-Regular.ttf') format('ttf'),
    url('../fonts/Circe/Circe-Regular.eot') format('eot');
  font-weight: 500;
}

@font-face {
  font-family: 'Circe';
  src: url('../fonts/Circe/Circe-Light.woff') format('woff'),
    url('../fonts/Circe/Circe-Light.ttf') format('ttf'),
    url('../fonts/Circe/Circe-Light.eot') format('eot');
  font-weight: 400;
}

@font-face {
  font-family: 'Jost';
  src: url('../fonts/Jost/Jost-VariableFont_wght.ttf')
    format('truetype');
}
