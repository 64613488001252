.rdrCalendarWrapper {
  color: #000000;
  font-size: 12px;
  margin-top: -5px;
  margin-left: -5px;
}

.rdrDateDisplayWrapper {
  background-color: #fff;
}

.rdrDateDisplay {
  margin: 0.833em;
}

.rdrDateDisplayItem {
  border-radius: 8px;
  background-color: rgba(0, 38, 55, 0.01);
  border: 1px solid rgba(0, 38, 55, 0.01);
  input {
    cursor: pointer;
    height: 42px;
    line-height: 2.5em;
    border: 0px;
    background: rgba(0, 38, 55, 0.05);
    width: 100%;
    color: #002637;
  }
}

.rdrMonthAndYearWrapper {
  align-items: center;
  height: 60px;
  padding-top: 10px;
  font-size: 16px;
}

.rdrDay {
  background: transparent;
  border: 0;
  padding: 0;
  line-height: 3em;
  height: 3em;
  text-align: center;
  color: #1d2429;
  &:focus {
    outline: 0;
  }
  font-size: 16px;
}

.rdrMonthPicker select {
  font-weight: 700;
}

.rdrYearPicker select {
  font-weight: 700;
}

.rdrDayNumber span {
  font-weight: 500;
}

.rdrPprevButton {
  background-color: transparent;
}

.rdrNextButton {
  background-color: transparent;
}
