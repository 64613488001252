.rbc-toolbar-label {
  font-weight: 600;
  font-size: large;
}

.rbc-btn-group button {
  font-weight: 500;
  color: rgba(88, 154, 175, 1);
}

.rbc-label {
  color: rgba(0, 38, 55, 1);
  font-weight: 500;
}

.rbc-events-container {
  width: 100%;
  overflow: hidden;
}

.rbc-time-column {
  height: 100%;
}

.rbc-events-container .rbc-event {
  transition: transform 0.3s ease;
  box-sizing: content-box;
  position: absolute;
  left: 0 !important;
}

.rbc-event:not(:first-child):hover {
  transform: scale(1.1);
}

.rbc-event::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease, transform 0.3s ease;
  transform: scale(1);
}

.rbc-event:not(:first-child):hover::after {
  opacity: 1;
  transform: scale(1.1);
}

.rbc-calendar {
  width: 100%;
  overflow: hidden;
}

.rbc-time-content {
  width: 100%;
  overflow: hidden;
}
