.faq-icon path {
  fill: #002637;
  transition: fill 0.3s ease;
}

.faq-icon:hover path {
  fill: #ffffff;
}

.button-with-icon:hover .faq-icon path {
  fill: #ffffff;
}
